.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1500px;
  height: 100%;
  width: 96%;
  margin: 0 auto;
  position: relative;
}
.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.text{
  margin: 0 auto;
  font-size: 15px;
}
.content {
  /*display: grid;*/
  /*grid-template-columns: 1fr 403px;*/
  width: 90%;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.personal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.personalForm {
  display: grid;
  grid-template-columns: repeat(2, 210px);
  column-gap: 30px;
  justify-content: center;
}
.personalFormCell {
  display: flex;
  flex-direction: column;
}
.avatarPlaceholder {
  border-radius: 100%;
  object-fit: cover;
  height: 86px;
  width: 86px;
}
.accountNumber {
  font-weight: 400;
  font-size: 13px;
  margin: 0 auto 22px;
  text-align: center;
  color: #626262;
}
.permissions {
  border-radius: 20px;
  background: #fff;
  box-shadow: 7px -1px 20px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 403px;
  padding-bottom: 45px;
}
.permissionsTitle {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.permissionsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label {
  color: #49454f;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  align-self: flex-start;
  margin-top: 30px;
}
.label_type_personal {
  margin-top: 24px;
}
.input {
  width: 255px;
  height: 33px;
  border-radius: 4px;
  border: 1px solid #79747e;
  color: #49454f;
  text-align: right;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  padding: 8px 10px;
  box-sizing: border-box;
}
.input_type_personal {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .section {
    justify-content: start;
  }
  .title {
    font-size: 20px;
  }
  .content {
    /*grid-template-columns: 1fr 300px;*/
    /*gap: 20px;*/
    width: 96%;
    padding-bottom: 10px;
  }
  .avatarPlaceholder{
    width: 42px;
    height: 42px;
  }
  .permissions {
    width: 300px;
    padding-bottom: 25px;
  }
  .permissionsTitle {
    font-size: 18px;
    margin-bottom: 0;
  }
  .label {
    font-size: 11px;
    line-height: 1.2;
    margin-top: 20px;
  }
  .label_type_personal {
    margin-top: 14px;
  }
  .input {
    width: 210px;
    height: 30px;
    font-size: 14px;
    padding: 6px 8px;
  }
}
@media screen and (max-width: 1000px) {
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .personal{
    width: 100%;
    max-width: 320px;
  }
  .personalForm{
    width: 100%;
  }
  .label{
    font-size: 14px;
  }
  .input {
    width: 98%;
    height: 34px;
    font-size: 16px;
    padding: 6px 8px;
  }
  .accountNumber {
    font-size: 18px;
    margin-top: 10px;
  }
  .personalForm{
    display: flex;
    flex-direction: column;
  }
}
