.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0 231px;
    direction: rtl;
    width: 95%;
    position: relative;
}
.section__title {
    margin: 12px auto 24px;
    color: #2f42c2;
    font-size: 24px;
    font-weight: 600;
}
@media screen and (max-width: 1400px) {
    .section {
        padding: 11px 0 40px;
    }

    .section__title {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 600px) {
    .section {
        padding: 10px 0 40px;
        width: 315px;
    }
    .section__title {
        font-size: 18px;
        margin-bottom: 20px;
    }
}
