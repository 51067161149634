.section{
    width: 95%;
    height: 100%;
    position: relative;
    padding: 20px 0;
    box-sizing: border-box;
}
.userInfoContainer{
    border-bottom: 1px solid #a19f9f;
    margin: 10px 0 20px;
}
.listRow{
    display: grid;
    grid-template-columns: 50px 1.4fr 1fr 1fr 1fr 1fr 1fr  110px;
    width: 100%;
    grid-gap: 10px;
    color: #626262;
}
.listRow_header{
    font-weight: 600;
    color: #00214e;
}
@media screen and (max-width: 1200px) {
    .listRow {
        grid-gap: 7px;
    }
}
