.question {
  display: block;
  margin-top: 4px;
  cursor: grab;
  color: #2c2b2b;
  max-width: 100%;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis;
}
.question::after {
  display: none;
}
