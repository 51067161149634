.popup{
    min-width: 380px;
    min-height: 180px;
}
.options{
    width: 100%;
}
.option{
    /*background-color: #5d3cc7;*/
    /*color: #d5d5d5;*/
    flex-direction: column;
}
.option__content{
    visibility: hidden;
    position: absolute;
    top: -10px;
    background-color: #ebebeb;
    border-radius: 10px;
    padding: 16px 8px;
    color: #121759;
    font-size: 16px;
    width: 100%;
    z-index: 3;
    box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
}
.option:hover .option__content {
    /*height: 100%;*/
    visibility: visible;
}
.label{
    margin: 0 2px;
    font-weight: 600;
}