.section {
  width: 98%;
  height: 100%;
  font-size: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section__header {
  margin: 32px 0 22px;
  width: 99%;
  display: flex;
  justify-content: space-around;
}
.section__title {
  margin: 12px 0 24px;
  text-align: center;
  color: #2f42c2;
  font-size: 24px;
  font-weight: 600;
}
.draggableContainer {
  width: 96%;
  display: grid;
  grid-template-columns: 50% 47%;
  gap: 26px;
  margin-bottom: 16px;
}
.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 17px;
}
@media screen and (max-width: 1400px) {
  .section {
    padding: 11px 0 40px;
    font-size: 16px;
  }
  .section__header {
    margin: 32px 0 12px;
  }
  .section__title {
    margin-bottom: 16px;
  }
  .list{
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .section {
    padding: 10px 0 40px;
    width: 97%;
  }
  .section__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .draggableContainer {
    width: 100%;
    gap: 6px;
  }

}
