.rrmHeader {
  margin-top: 4px;
  display: grid;
  grid-template-columns: 1fr 110px 30px;
}
.rrmHeader::after {
  display: none;
}
.rrmHeader:hover .questions {
  height: 100%;
}
.questions {
  height: 0;
  overflow: hidden;
  padding: 0 16px 0 0;
  color: #121759;
  font-size: 16px;
  grid-column: 1 / -1;
  width: 100%;
}
.question {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 20px;
  gap: 5px;
}
.btn{
    outline: none;
    border: none;
    cursor: pointer;
    color: #da1e28;
    font-weight: 600;
    background-color: transparent;
}