.support {
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px 0;
  box-sizing: border-box;
}
.support__title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #00214e;
  margin: 56px 0;
}
.support__text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #606060;
  margin: 12px auto;
}
.support__form {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.support__label {
  margin: 7px 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.78;
  color: #121759;
}
.support__input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 41px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
}
.support__textArea {
  box-sizing: border-box;
  width: 100%;
  min-height: 211px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  font-size: 18px;
  padding: 10px 20px;
}
.support__btn {
  margin: 40px auto 0;
}
.support__btn_disabled {
  background-color: #dbd4f4;
}
.support__btn:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}
@media screen and (max-width: 1300px) {
  .support__title {
    font-size: 15px;
    margin-top: 13px;
  }
  .support__text {
    font-size: 15px;
    line-height: 1.1;
  }
  .support__label {
    font-size: 15px;
    margin: 0 0 3px;
  }
  .support__input{
    font-size: 16px;
  }
  .support__textArea {
    min-height: 187px;
    font-size: 16px;
  }
  .support__btn {
    margin-top: 17px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .support {
    width: calc(100% - 78px);
    align-items: center;
    padding: 0 0 20px;
  }
  .support__title {
    line-height: 1.2;
    margin: 0 auto 2px;
  }
  .support__text {
    display: none;
  }
  .support__form {
    width: 282px;
  }
  .support__input {
    width: 100%;
  }
  .support__textArea {
    width: 100%;
  }
  .support__btn {
    margin: 13px auto 0;
  }
}
