body {
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout {
  background: #fbfaff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Assistant", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  background: url("../images/background.jpg") top 20px right no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-position-y: 45px;
}
.modals {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  z-index: 100;
}
.hover:hover {
  transform: translateY(-3px);
  opacity: 0.8;
}

html::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
/* Handle */
html::-webkit-scrollbar-thumb {
  opacity: 0.4;
  background: #eae6df;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a19f9f;
}
.scroll::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
.scroll_thin::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: #0044c9;
  opacity: .5}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a19f9f;
}

.scroll_violet::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
/* Handle */
.scroll_violet::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
.scroll_violet::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scroll_violet::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 60px;
}

.button {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  max-width: 290px;
  height: 57px;
  background: #5d3cc7;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.button_white {
  background: #fff;
  border: 1px solid rgba(57, 23, 169, 0.81);
  color: rgba(57, 23, 169, 0.81);
}
.button_disabled {
  opacity: 0.5;
  cursor: unset;
}
input,
button,
textarea {
  font-family: inherit;
}
.error {
  font-size: 16px;
  line-height: 1;
  color: #ff0000;
  text-align: center;
}
.listRow_mobile{
  display: grid;
  grid-template-columns: 100px 1fr 1fr;
  gap: 0;
  font-size: 14px;
  background-color: #fff;
  padding: 8px 8px 8px 0;
  width: 98%;
  border-radius: 4px;
  box-sizing: border-box;
  color: #9c9c9c;
  height: 43px;
  overflow: hidden;
  transition: all 0.4s;
  margin-bottom: 12px;
}
.listRow_mobile_long{
  height: unset;
}
.title {
  margin: 0 auto 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #00214e;
  text-align: center;
}
.title_blue{
  color: #2f42c2;
}
.text{
  font-size: 18px;
  text-align: center;
}
.options {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 304px);
  column-gap: 10%;
  row-gap: 14px;
}
.option {
  text-decoration: none;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 3px 27px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 41px;
  color: #2f42c2;
  position: relative;
}
.option:after {
  content: "";
  position: absolute;
  top: 40%;
  left: 20px;
  height: 16px;
  width: 8px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  background-image: url("../images/arrow_left_blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.input {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 17px;
  line-height: 1.2;
  text-align: right;
  color: #000;
  padding: 8px 17px;
  background: #fff;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  width: 100%;
}
.input:focus,
.input:focus-visible {
  outline: #2684ff;
  border: 2px solid #2684ff;
}
.input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}
.centered_item{
  margin: auto;
}
.colored{
  background-color: #cee7ff;
}

@media screen and (max-width: 1300px) {
  .title {
    font-size: 20px;
    margin: 0 auto 16px;
  }
  .text{
    font-size: 17px;
  }
  .options {
    width: 75%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    font-size: 17px;
  }
  .option {
    padding: 14px 22px;
  }
  .option:after {
    left: 15px;
    height: 14px;
    width: 7px;
  }
  .button {
    max-width: 190px;
    height: 42px;
    min-height: 42px;
    font-size: 16px;
  }
  .input {
    font-size: 14px;
    border-radius: 6px;
    padding: 6px 15px;
  }

}

@media screen and (max-width: 1200px) {
  .input {
    height: 36px;
  }

}
@media screen and (max-width: 1024px) {
  .error {
    font-size: 14px;
  }
  .text{
    font-size: 15px;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 17px;
  }
}
@media screen and (max-width: 600px) {
  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 14px;
    margin-bottom: 30px;
  }

  .option {
    width: 270px;
  }
  .input {
    font-size: 16px;
  }

}