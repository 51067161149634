.list {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  list-style-type: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  width: 97%;
}

.list::-webkit-scrollbar {
  background: #e7f2fd9e;
  width: 8px;
}
.listRow {
  grid-gap: 10px;
  color: #626262;
  display: grid;
  grid-template-columns: 40px 40px 1fr 60px 100px 80px 80px 120px;
  width: 100%;
}
.listRow_header {
    color: #00214e;
    font-size: 17px;
    font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .list {
    font-size: 14px;
    padding: 10px 0;
    row-gap: 12px;
    align-items: flex-start;
    display: block;
  }
    .listRow {
        grid-gap: 8px;
        grid-template-columns: 40px 40px 1fr 58px 88px 70px 70px 120px;
    }
    .listRow_header {
        font-size: 16px;
    }
}
@media screen and (max-width: 800px) {
  .list {
    align-items: flex-start;
    display: block;
    padding: 10px 0;
    row-gap: 12px;
  }
}
