.link{
    position: absolute;
    top: 2%;
    left: 0;
    z-index: 2;
    background-color: #fff;
    cursor: pointer;
}
.link__link{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 4px;
    margin: 0 auto;
    background-color: #cee7ff;
    border: 1px solid #0044c9;
    color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 4px 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
}
.link__text{
    margin: 0 auto;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;
    font-size: 12px;
    color: #626262;
    line-height: 1;
    text-align: center;
}
.text{
    text-decoration: none;
    color: #2c2b2b;
    font-weight: 400;
    cursor: unset;
}
@media screen and (max-width: 1200px) {
    .link__link {
        font-size: 14px;
    }
}@media screen and (max-width: 600px) {
    .link {
        top: 16px;
    }
}