.popup {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup__title {
    margin: 0 auto 20px;
    color: #2f42c2;
    font-size: 24px;
    font-weight: 600;
}
.popup__img {
    width: 120px;
    height: 120px;
    margin: 14px auto;
}
.popup__nextBtn {
    margin: 26px auto 12px;
    width: 260px;
    height: 52px;
}
.popup__nextBtn_disabled{
    background-color: #dbd4f4;
}
.popup__text{
    margin: 6px 0;
    font-size: 19px;
    line-height: 1.1;
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .popup__title {
        margin: 8px auto;
        font-size: 18px;
    }
    .popup__img {
        width: 57px;
        height: 58px;
        margin: 0 auto;
    }
    .popup__text{
        margin: 4px 0;
        font-size: 16px;
    }
    .popup__nextBtn {
        margin: 12px auto 5px;
        width: 203px;
        height: 42px;
        font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
    .popup__text{
        margin: 6px 0;
        font-size: 16px;
        line-height: 1.2;
    }
    .popup__nextBtn {
        width: 159px;
        height: 36px;
        font-size: 14px;
        margin-top: 18px;
    }
}