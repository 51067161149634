.listRow{
  display: grid;
  grid-template-columns: 120px 1fr 140px 100px  130px;
  width: 100%;
  grid-gap: 10px;
  color: #626262;
}
.listRow_header{
  font-weight: 600;
  color: #00214e;
}
@media screen and (max-width: 1200px) {
  .listRow {
    grid-gap: 8px;
  }
}
@media screen and (max-width: 1000px) {
  .listRow {
    grid-template-columns: 50px 1fr 126px 60px 120px;
  }
}
