.form {
  width: 100%;
}
.form_type_addUser {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto 42px;
  border-bottom: 1px solid #0044c9;
}
.form__questionsContainer {
  display: grid;
  justify-items: flex-start;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 18px;
}
.form__note {
  margin: 0;
  font-size: 16px;
  color: #626262;
}
.form__formField {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form__title {
  font-size: 18px;
  text-align: center;
}
.form__noMargin {
  margin: 0;
}
.form__bold {
  font-weight: 600;
}
.form__list {
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  font-size: 18px;
  align-items: baseline;
}
.form__list_type_fourColumns {
  grid-template-columns: repeat(4, 1fr);
}
.form__list_type_threeColumns {
  grid-template-columns: 1fr 1fr 1fr;
}
.form__list_type_lastLiWide{}
.form__list_type_lastLiWide li:last-child {
  grid-column: 1 / -1; /* Span all columns */
}
.form__list_type_lastTwoLiWide{}
.form__list_type_lastTwoLiWide li:nth-last-child(-n+2) {
  grid-column: 1 / -1; /* Span all columns */
}

.form__list_type_options {
  grid-template-columns: 1fr;
}
.form__label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.7;
  color: #626262;
  margin-right: 5px;
}
.select{
  font-size: 17px;
}
.form__input {
  max-height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: inherit;
  font-size: 17px;
  line-height: 1.2;
  text-align: right;
  color: #000;
  padding: 8px 17px;
  background: #fff;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  width: 100%;
}
.form__input:focus,
.form__input:focus-visible {
  outline: #2684ff;
  border: 2px solid #2684ff;
}
.form__input_invalid {
  background: #ffe9e9;
  border: 1px solid #ff0000;
}
.form__input_disabled {
  background-color: rgb(242, 242, 242);
  opacity: 0.8;
}
.form__input::placeholder {
  color: inherit;
}
.form__header{
display: flex;
  justify-content: space-between;
}
.form__option {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 1fr 1fr 0.5fr;
  gap: 10px;
  align-items: flex-start;
}
.form__textArea {
  width: 100%;
}
.form__datePicker {
  width: 100%;
  display: block;
}
.form__btn {
  margin: 43px auto;
  width: 212px;
  height: 46px;
  font-size: 16px;
  position: relative;
}
.form__btn_disabled {
  opacity: 0.5;
  cursor: unset;
}
.form__btn_disabled:hover {
  opacity: 0.5;
  cursor: unset;
  transform: unset;
}
.form__btn_add {
  display: block;
  margin: 10px auto 0;
}
.liWide {
  grid-column: 1 / -1;
}
.submitBtn {
  margin: 20px auto;
}
@media screen and (max-width: 1400px) {
  .form__list {
    grid-auto-rows: max-content;
    gap: 20px;
    font-size: 16px;
  }
  .form__label {
    font-size: 14px;
    margin-right: 5px;
  }
  .select{
    font-size: 15px;
  }
  .form__input {
    height: 42px;
    font-size: 15px;
    border-radius: 6px;
    padding: 6px 15px;
  }
  .form__textArea {
    width: 100%;
  }
  .form__btn {
    margin-top: 33px;
    width: 190px;
    height: 42px;
  }
}

@media screen and (max-width: 1200px) {
  .form__list {
    row-gap: 10px;
    font-size: 15px;
  }
  .form__input {
    height: 36px;
    min-height: 36px;
  }
}
@media screen and (max-width: 1000px) {
  .form__list_type_fourColumns {
    grid-template-columns: repeat(2, 1fr);
  }
  .form__list_type_threeColumns {
    grid-template-columns: repeat(2, 1fr);
  }

}
@media screen and (max-width: 900px) {
  .form__questionsContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media screen and (max-width: 840px) {
  .form__list {
    width: calc(100% - 20px);
    column-gap: 40px;
    font-size: 14px;
  }
}

@media screen and (max-width: 680px) {
  .form__list {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .form_type_addUser {
    width: calc(100% - 80px);
    margin-bottom: 42px;
  }
  .form__questionsContainer {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .form__list {
    gap: 18px;
    width: 100%;
  }
  .form__list_type_fourColumns {
    grid-template-columns: 1fr;
  }
  .form__list_type_threeColumns {
    grid-template-columns: 1fr;
  }

  .form__label {
    font-size: 15px;
    line-height: 31px;
    color: #121759;
  }
  .form__textArea {
    width: 100%;
  }
  .form__btn {
    margin-top: 50px;
    width: 260px;
    height: 57px;
    font-size: 18px;
  }
}
