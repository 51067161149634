.section {
  width: 96%;
  height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  position: relative;
}
.btn_container {
  display: flex;
  justify-content: flex-end;
}
.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  font-size: 16px;
  color: #2c2b2b;
    gap: 10px;
}
.li {
  display: grid;
    grid-template-columns: 30px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.li_header {
  color: #00214e;
  font-size: 17px;
  font-weight: 600;
}
@media screen and (max-width: 1300px) {
  .list {
    font-size: 15px;
  }
  .li {
    gap: 6px;
  }
  .li_header {
    font-size: 16px;
  }
}
