.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__title {
  margin: 0 auto 52px;
  color: #2f42c2;
  font-size: 28px;
  font-weight: 700;
}
.popup__img {
  width: 145px;
  height: 145px;
  margin: 31px auto;
}
.popup__nextBtn {
  margin: 50px auto 27px;
  width: 260px;
}

@media screen and (max-width: 1400px) {
  .popup__title {
    margin: 8px auto 40px;
    font-size: 20px;
  }
  .popup__img {
    width: 113px;
    height: 113px;
    margin: 20px auto;
  }
  .popup__nextBtn {
    margin: 8px auto 5px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .popup__title {
    margin: 0 auto 33px;
  }
  .popup__img {
    width: 88px;
    height: 88px;
    margin: 13px auto;
  }
  .popup__nextBtn {
    width: 159px;
    height: 35px;
    font-size: 12px;
    margin-top: 11px;
  }
}
