.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popup__title {
  margin: 0 auto 42px;
  color: #2f42c2;
  font-size: 28px;
  font-weight: 700;
}
.popup__list {
  width: 97%;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.popup__li {
  box-sizing: border-box;
  padding: 4px;
  display: grid;
  gap: 4px;
  grid-template-columns: 0.5fr 1fr 1fr 0.6fr;
}
.popup__li_header{
  color: #00214e;
  font-weight: 600;
}
.popup__li_colored{
  background-color: #cee7ff;
}
.popup__nextBtn {
  margin: 50px auto 27px;
  width: 260px;
}

@media screen and (max-width: 1400px) {
  .popup__title {
    margin: 8px auto 12px;
    font-size: 20px;
  }
  .popup__list {
    font-size: 17px;
  }
  .popup__li{
    font-size: 16px;
  }
  .popup__nextBtn {
    margin: 12px auto 5px;
    width: 203px;
    height: 45px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .popup__title {
    margin: 0 auto 33px;
  }
  .popup__nextBtn {
    width: 159px;
    height: 35px;
    font-size: 12px;
    margin-top: 11px;
  }
}
