.tooltip {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  left: 20px;
  margin-top: 5px;
  padding: 0.18rem 0.8rem;
  white-space: nowrap;
  color: #121759;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 3;
}
.tooltip_position_center{
  left: -180%;
}
.tooltip_visible{
  bottom: 130%;
  opacity: 0.7;
}