.questionnaires {
  width: 96%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-top: 20px;
}
.header {
  width: 97%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 115px 1fr 230px;
  font-size: 16px;
  gap: 15px;
}
.addBtn{
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #24a148;
  border-radius: 4px;
  height: 38px;
}
.plus{
  margin: 0 4px;
  color: #24a148;
  font-weight: 600;
}
.searchInput {
  padding: 10px 20px;
  box-sizing: border-box;
  font: inherit;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  line-height: 1;
  height: 38px;
}
.list{
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 97%;
  overflow-y: auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list::-webkit-scrollbar {
  width: 8px;
  background: rgba(231, 242, 253, 0.62);
}
.listRow{
  display: grid;
  grid-template-columns: 120px 120px 1fr 100px 100px  130px 130px;
  width: 100%;
  grid-gap: 10px;
  color: #626262;
}
.listRow_header{
  color: #00214e;
  font-size: 18px;
}

.listRow_mobile .listCell:nth-child(5) {
  grid-column: 2 / 4;
}
.listRow_colored{
  background-color: #cee7ff;
}
.listCell{
  padding: 10px 8px;
  margin: 0;
}
.listValue{
  margin-right: 4px;
  color: #000;
  font-size: 15px;
}
.btn{
  outline: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 4px 6px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  position: relative;
}
.btn_type_edit{
  background-image: url("../../images/edit_icon.svg");
}
.btn_type_edit_grey{
  background-image: url("../../images/edit_icon_grey.svg");
}
.btn_type_delete{
  background-image: url("../../images/delete_icon.svg");
}.btn_type_options{
  background-image: url("../../images/options_icon.svg");
}
.btn_type_questions{
  background-image: url("../../images/question_icon.svg");
}.btn_type_rrm{
  background-image: url("../../images/rrm_icon.svg");
}.btn_type_component{
  background-image: url("../../images/add_component_icon.svg");
}.btn_type_schedule{
  background-image: url("../../images/schedule_icon.svg");
}
.text{
  font-size: 18px;
}
@media screen and (max-width: 1200px) {
  .listRow{
    grid-gap: 8px;
  }
  .select {
    font-size: 15px;
  }
  .searchInput {
    padding: 8px 15px;
    font-size: 15px;
  }
  .listRow_header{
    font-size: 16px;
  }
  .list{
    font-size: 14px;
  }
  .listCell{
    padding: 4px 6px;
  }
}
@media screen and (max-width: 1000px) {
.listRow{
  grid-template-columns: 50px 50px 1fr 90px 60px  60px 120px;
}
}
@media screen and (max-width: 800px) {
  .questionnaires{
    width: 98%;
  }
  .header{
    margin: 0 0 10px;
    grid-template-columns: 30px 1fr 1fr;
    font-size: 16px;
    gap: 10px;
  }
  .list{
    display: block;
    padding: 10px 0;
    align-items: flex-start;
    row-gap: 12px;
  }
  .listRow_colored{
    background-color: unset;
  }
}
