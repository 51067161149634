.popup {
  display: flex;
  gap: 14px;
  flex-direction: column;
  align-items: center;
  color: #111111;
  font-size: 18px;
}
.popup__title {
  margin: 0 auto 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  color: #2f42c2;
}
.select{
  width: 100%;
}
.input{
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  padding: 8px 12px;
}
.textarea{
  width: 100%;
  box-sizing: border-box;
  padding: 4px 10px;
  min-height: 10px;
}
.textarea_small{
  min-height: 60px;
}
.popup__btn {
  height: 42px;
  border-radius: 5px;
}
.popup__nextBtn {
  color: #5d3cc7;
  border: 1px solid #5d3cc7;
  background: transparent;
}
@media screen and (max-width: 1300px) {
  .popup{
    font-size: 16px;
  }
  .popup__title {
    font-size: 19px;
  }
  .popup__text {
    font-size: 14px;
  }
  .popup__text_big {
    margin: 0 auto 30px;
    font-size: 16px;
  }
  .popup__text_big:last-of-type {
    margin-bottom: 15px;
  }
  .popup__nextBtn {
    font-size: 16px;
  }
  .popup__btn {
    width: 220px;
    height: 38px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .popup__title {
    font-size: 19px;
    margin-bottom: 20px;
  }
  .popup__btn {
    height: 42px;
    width: 190px;
    font-size: 16px;
  }

}
