.section {
  margin: 0 auto;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 600px) {
  .btn{
    width: 270px;
    font-size: 18px;
  }
}
