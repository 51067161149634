.section{
    width: 97%;
    height: 100%;
    position: relative;
}
.section__title {
    margin: 12px auto 24px;
    color: #2f42c2;
    font-size: 24px;
    font-weight: 600;
    width: fit-content;
}
