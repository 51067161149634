.main {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 298px;
  position: relative;
}
.main_chat {
  grid-template-columns: 1fr 293px 298px;
  transition: all 0.7s;
}
.main_chat_small {
  grid-template-columns: 1fr 293px 136px;
}
.mainContent {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  padding-top: 100px;
  box-sizing: border-box;
  margin-left: 3px;
}
.mainContent::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
  z-index: 5;
}
/* Handle */
.mainContent::-webkit-scrollbar-thumb {
  border-radius: 4px;
  opacity: 0.4;
  background: #2f42c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mainContent_chat {
  height: 100vh;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding-right: 298px;
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.07);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  direction: initial;
}
.header_chat {
  padding-right: 445px;
}
.mainContent__title {
  margin: 0;
  font-weight: 700;
  font-size: 36px;
  color: #2f42c2;
}
.mainContent__bell {
  outline: none;
  border: none;
  position: absolute;
  cursor: pointer;
  background-color: transparent;
  width: 21px;
  height: 24px;
  top: 38px;
  left: 83px;
  background-image: url("../../images/bell_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mainContent__bell_invisible {
  background-image: none;
}
.link {
  align-self: flex-start;
  cursor: pointer;
  font-size: 16px;
  color: #8d8d8d;
  margin-left: 12px;
}
.burger {
  position: absolute;
  top: 27px;
  right: 25px;
  cursor: pointer;
  display: none;
  background-image: url("../../images/burger_icon.svg");
  width: 23px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  outline: none;
  border: none;
  background-color: transparent;
  z-index: 2;
}
.overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 4;
}
.overlay_visible {
  display: block;
}

@media screen and (max-width: 1480px) {
  .main {
    grid-template-columns: 1fr 272px;
  }
  .header {
    padding-right: 272px;
  }
  .header_chat {
    padding-right: 445px;
  }
  .main_chat {
    grid-template-columns: 1fr 293px 272px;
  }
  .main_chat_small {
    grid-template-columns: 1fr 293px 136px;
  }
}

@media screen and (max-width: 1400px) {
  .mainContent {
    padding-top: 79px;
  }
  .header {
    height: 79px;
  }
  .header_chat {
    padding-right: 435px;
  }
  .link {
    height: 13px;
  }
  .mainContent__title {
    font-size: 32px;
  }
  .mainContent__bell {
    width: 20px;
    height: 24px;
    top: 30px;
    left: 25px;
  }
}
@media screen and (max-width: 1300px) {
  .main {
    grid-template-columns: 1fr 143px;
  }
  .mainContent{
    padding-top: 65px;
  }
  .header {
    padding-right: 143px;
    height: 65px;
  }
  .mainContent__title{
    font-size: 30px;
  }
  .header_chat {
    padding-right: 355px;
  }
  .main_chat {
    grid-template-columns: 1fr 266px 143px;
  }
  .main_chat_small {
    grid-template-columns: 1fr 266px 102px;
  }
}
@media screen and (max-width: 750px) {
  .main_chat {
    grid-template-columns: 1fr 226px 143px;
  }
  .main_chat_small {
    grid-template-columns: 1fr 226px 102px;
  }
  .link {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .main {
    grid-template-columns: 1fr 0;
    overflow-x: hidden;
  }
  .mainContent {
    padding-top: 100px;
    height: fit-content;
  }
  .mainContent_chat {
    padding-top: 85px;
    height: 100vh;
  }
  .header {
    height: 70px;
    padding: 0;
  }
  .burger {
    display: block;
  }
  .mainContent__bell {
    background-image: url("../../images/bell_icon_blue.svg");
    top: 27px;
    left: 25px;
  }
  .mainContent__title {
    margin: 0;
    font-size: 24px;
  }
}
