.sidebar {
  z-index: 5;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
}
.sidebar_chat {
  background: rgba(231, 242, 253, 0.6);
}
.sidebar__content_visible {
  position: relative;
  width: 272px;
  background-color: #5d3cc7;
  border-radius: 55px 0 0 0;
  transition: all 0.7s;
  display: flex;
  flex-direction: column;
}
.scrollable {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scrollable::-webkit-scrollbar {
  position: absolute;
  left: 100px;
  width: 5px;
  opacity: 0.5;
  background: rgba(231, 242, 253, 0.62);
}
/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background-color: #8a74d8;
  border-radius: 5px;
}
.scrollable::-webkit-scrollbar:horizontal {
  display: none;
}
/* Handle on hover */
.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: #8a74d8;
}
.sidebar__content_small {
  width: 136px;
}
.sidebar__logo {
  width: 215px;
  height: 84px;
  background-image: url("../../images/logo_white.svg");
  margin: 20px auto 33px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: all 0.7s;
}
.sidebar__content_small > .sidebar__logo {
  background-image: url("../../images/logo_white_small.svg");
  width: 61px;
}
.sidebar__arrow {
  position: absolute;
  top: 123px;
  left: -15px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #a9a8ab;
  border-radius: 100%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar__arrow:before {
  content: "";
  background-image: url("../../images/arrow_right.svg");
  background-position: center;
  display: block;
  width: 8px;
  height: 12px;
}
.sidebar__content_small > .sidebar__arrow:before {
  background-image: url("../../images/arrow_left.svg");
}
.sidebar__welcome {
  box-sizing: border-box;
  display: grid;
  padding: 0 24px;
  grid-template-columns: 1fr 60px;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}
.sidebar__content_small .sidebar__welcome {
  width: 136px;
  grid-template-columns: 0 1fr;
  gap: 0;
  color: transparent;
}
.sidebar__title {
  margin: 0;
  width: fit-content;
  justify-self: flex-end;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
  text-align: center;
  direction: rtl;
  transition: all 0.7s;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  max-width: 100%;
  overflow: hidden;
}
.sidebar__content_small .sidebar__title {
  opacity: 0;
  width: 0;
  color: transparent;
}
.sidebar__avatar {
  cursor: pointer;
  display: inline-block;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
}
.sidebar__content_small .sidebar__avatar {
  margin: 0 auto;
}
.sidebar__pages {
  padding: 0;
  margin: 15px 0 0;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #5d3cc7;
}
.sidebar__page {
  box-sizing: border-box;
  cursor: pointer;
  height: 73px;
  width: 100%;
  min-width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.29;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 58px;
  grid-template-areas: "title icon";
  gap: 24px;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  transition: width 0.7s;
  text-decoration: none;
}
.sidebar__page:hover {
  opacity: 0.6;
}
.sidebar__page_messages {
  grid-template-columns: 64px 1fr 58px;
  grid-template-areas: ". title icon";
}
.sidebar__page_active .sidebar__pageIcon_myDay {
  background-image: url("../../images/home_icon_active.svg");
}
.sidebar__page_active .sidebar__pageIcon_support {
  background-image: url("../../images/support_icon_active.svg");
}
.sidebar__page_active .sidebar__pageIcon_prevencher {
  background-image: url("../../images/prevencher_icon_active.svg");
}
.sidebar__page_active .sidebar__pageIcon_forum {
  background-image: url("../../images/forum_icon_sidebar_active.svg");
}.sidebar__page_active .sidebar__pageIcon_users {
  background-image: url("../../images/users_icon_active.svg");
}.sidebar__page_active .sidebar__pageIcon_protocols {
  background-image: url("../../images/protocols_icon_active.svg");
}
.sidebar__page_active .sidebar__messages {
  background-color: #fff;
}
.sidebar__pageIcon {
  grid-area: icon;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.sidebar__pageIcon_myDay {
  background-image: url("../../images/home_icon.svg");
}
.sidebar__pageIcon_support {
  background-image: url("../../images/support_icon.svg");
}
.sidebar__pageIcon_prevencher {
  background-image: url("../../images/prevencher_icon.svg");
}.sidebar__pageIcon_forum {
  background-image: url("../../images/forum_icon_sidebar.svg");
}.sidebar__pageIcon_users {
  background-image: url("../../images/users_icon.svg");
}.sidebar__pageIcon_protocols {
  background-image: url("../../images/protocols_icon.svg");
}
.sidebar__content_small .sidebar__page {
  width: 136px;
  grid-template-columns: 0 1fr;
  gap: 0;
  overflow: hidden;
}
.sidebar__content_small .sidebar__page_messages {
  grid-template-areas: "title icon";
}
.sidebar__pageTitle {
  grid-area: title;
  margin: 0;
  width: fit-content;
  justify-self: flex-end;
  text-align: center;
  direction: rtl;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  transition: width, opacity 0.7s;
}
.sidebar__content_small .sidebar__pageTitle {
  opacity: 0;
  width: 0;
  color: transparent;
}
.sidebar__pageIcon {
  width: 32px;
  height: 32px;
}
.sidebar__content_small .sidebar__pageIcon {
  margin: 0 auto;
}
.sidebar__messages {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #e0daf4;
  color: #000;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
}
.sidebar__content_small .sidebar__messages {
  display: none;
}
.sidebar__footer {
  padding: 0;
  margin: 20px 0 37px;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: #ffffff;
  background-color: #5d3cc7;
}
.sidebar__footerItem {
   box-sizing: border-box;
   cursor: pointer;
   width: 100%;
   font-style: normal;
   font-weight: 300;
   font-size: 20px;
   line-height: 26px;
   display: grid;
   grid-template-columns: 1fr 58px;
   gap: 28px;
   align-content: center;
   align-items: center;
   justify-content: flex-end;
   text-decoration: none;
   color: #fff;
 }
.sidebar__footerItem:hover {
  opacity: 0.6;
}
.sidebar__page_active .sidebar__footerItem {
  color: #121759;
  background: #e0daf4;
}
.sidebar__content_small .sidebar__footerItem {
  grid-template-columns: 0 1fr;
  gap: 0;
  justify-items: center;
}
.sidebar__page_active {
  background: #ebebeb;
  color: #121759;
}
.sidebar__footerIcon {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.sidebar__footerIcon_logout {
  background-image: url("../../images/logout_icon.svg");
}
.sidebar__footerTitle {
  margin: 0;
  width: fit-content;
  justify-self: flex-end;
  text-align: center;
  direction: rtl;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: opacity 0.1s 0.1s;
}
.sidebar__content_small .sidebar__footerTitle {
  color: transparent;
  opacity: 0;
  width: 1px;
}
.link {
  color: inherit;
  text-decoration: none;
}
@media screen and (max-width: 1480px) {
  .sidebar__logo {
    width: 179px;
    margin: 33px auto 52px;
  }
  .sidebar__welcome {
    padding: 0 31px;
    grid-template-columns: 1fr 54px;
    gap: 9px;
  }
  .sidebar__sidebarTitle {
    font-size: 27px;
    line-height: 35px;
  }
  .sidebar__avatar {
    width: 54px;
    height: 54px;
  }
  .sidebar__pages {
    gap: 0;
    margin-top: 33px;
  }
  .sidebar__page {
    height: 65px;
    font-size: 20px;
    line-height: 26px;
  }
  .sidebar__pageIcon {
    width: 24px;
    height: 24px;
  }
  .sidebar__messages {
    width: 22px;
    height: 22px;
  }
  .sidebar__footer {
    gap: 0;
  }
  .sidebar__footerItem {
    padding: 8px;
    font-size: 18px;
    line-height: 24px;
    grid-template-columns: 1fr 50px;
    gap: 24px;
  }
  .sidebar__page_active .sidebar__footerItem {
    color: #121759;
    background: #e0daf4;
  }
}
@media screen and (max-width: 1300px) and (min-width: 601px) {
  .sidebar__content {
    width: 159px;
    border-radius: 41px 0 0 0;
  }
  .sidebar__content_visible {
    border-radius: 41px 0 0 0;
    width: 143px;
  }
  .sidebar__arrow {
    width: 22px;
    height: 22px;
    top: 94px;
    left: -11px;
    padding: 0;
  }
  .sidebar__arrow:before {
    width: 4px;
    height: 9px;
    background-size: cover;
  }
  .sidebar__content_small {
    width: 102px;
  }
  .sidebar__logo {
    width: 121px;
    margin: 8px auto 45px;
  }
  .sidebar__welcome {
    padding: 0 10px;
    grid-template-columns: 1fr 43px;
    gap: 7px;
  }
  .sidebar__content_small .sidebar__welcome {
    width: 102px;
  }
  .sidebar__title {
    font-size: 21px;
    line-height: 28px;
  }
  .sidebar__avatar {
    width: 43px;
    height: 43px;
  }
  .sidebar__pages {
    gap: 11px;
    margin-top: 14px;
  }
  .sidebar__page {
    height: 58px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "icon" "title";
    justify-items: center;
    gap: 0;
    overflow: hidden;
  }
  .sidebar__content_small .sidebar__page {
    width: 102px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0;
  }
  .sidebar__content_small .sidebar__page_messages {
    grid-template-areas: "icon";
    display: flex;
  }
  .sidebar__content_small .sidebar__pageTitle {
    font-size: 0;
    color: transparent;
  }
  .sidebar__pageTitle {
    width: 100%;
  }
  .sidebar__pageIcon {
    width: 20px;
    height: 20px;
  }
  .sidebar__messages {
    display: none;
  }
  .sidebar__footer {
    padding-bottom: 16px;
    margin-bottom: 0;
  }
  .sidebar__footerItem {
    padding: 6px 0;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    grid-template-columns: 1fr 29px;
    gap: 14px;
  }
  .sidebar__footerIcon {
    width: 12px;
    height: 12px;
    background-size: contain;
  }
  .sidebar__page_active .sidebar__footerItem {
    color: #121759;
    background: #e0daf4;
  }
}

@media screen and (max-width: 600px) {
  .sidebar {
    width: 240px;
    transform: translateX(250px);
    transition: all 0.7s;
  }
  .sidebar__placeholder {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .sidebar__content {
    width: 240px;
  }
  .sidebar__arrow {
    display: none;
  }
  .sidebar_open {
    transform: translateX(0);
  }
  .sidebar__logo {
    width: 150px;
    margin: 15px auto 18px;
  }
  .sidebar__welcome {
    padding: 0 24px;
    grid-template-columns: 1fr 37px;
    gap: 6px;
  }
  .sidebar__title {
    font-size: 18px;
    line-height: 24px;
  }
  .sidebar__avatar {
    width: 37px;
    height: 37px;
  }
  .sidebar__pages {
    margin-top: 22px;
    gap: 10px;
  }
  .sidebar__page {
    height: 45px;
    font-size: 16px;
    line-height: 21px;
    grid-template-columns: 1fr 36px;
    gap: 17px;
  }
  .sidebar__pageIcon {
    width: 19px;
    height: 19px;
  }
  .sidebar__page_messages {
    grid-template-columns: 56px 1fr 36px;
  }
  .sidebar__footerItem {
    font-size: 15px;
    line-height: 19px;
    grid-template-columns: 1fr 28px;
  }
  .sidebar__footerIcon {
    width: 14px;
    height: 14px;
  }
}
