.btn{
    margin: 0 auto 0 0;
    height: 100%;
    width: fit-content;
    align-self: flex-end;
    background-color: initial;
    border: none;
    color: #1f8d3e;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.1;
    outline: none;
}
.btn__plus{
    color: #24a148;
    font-size: 14px;
    margin-left: 4px;
}
