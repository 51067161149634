.form__list_four_columns {
grid-template-columns: repeat(4, 1fr);
}
.form__list li:last-child {
    grid-column: 1 / -1;
}
.form__link {
    text-decoration: underline;
    margin: 0;
    color: #121759;
    cursor: pointer;
}
.label{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .form__list_four_columns {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width: 600px) {
    .form__list_four_columns {
        grid-template-columns: 1fr;
    }
}