.form {
  width: 100%;
}
.form__title {
  font-size: 18px;
  text-align: center;
}
.form__noMargin {
  margin: 0;
}
.form__bold{
    font-weight: 600;
}
.form__list {
  padding: 0;
  margin: 0 auto;
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  font-size: 18px;
  align-items: baseline;
}
.form__list_type_options {
  grid-template-columns: 1fr;
}
.form__label {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.7;
  color: #626262;
  margin-right: 5px;
}
.form__option {
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 80px 1fr 1fr 0.5fr;
  gap: 10px;
  align-items: flex-start;
}
.form__textArea {
  width: 100%;
}
.form__datePicker {
  width: 100%;
  display: block;
}
.form__btn {
  margin: 43px auto;
  width: 212px;
  height: 46px;
  font-size: 16px;
  position: relative;
}
.form__btn_disabled {
  opacity: 0.5;
  cursor: unset;
}
.form__btn_disabled:hover {
  opacity: 0.5;
  cursor: unset;
  transform: unset;
}
.form__btn_add{
  display: block;
  margin: 10px auto 0;
}

@media screen and (max-width: 1400px) {
  .form__list {
    grid-auto-rows: max-content;
    gap: 20px;
    font-size: 16px;
  }
  .form__label {
    font-size: 14px;
    margin-right: 5px;
  }
  .form__textArea {
    width: 100%;
  }
  .form__btn {
    margin-top: 33px;
    width: 190px;
    height: 42px;
  }
}

@media screen and (max-width: 1200px) {
  .form__list {
    row-gap: 10px;
    font-size: 15px;
  }
}
@media screen and (max-width: 840px) {
  .form__list {
    width: calc(100% - 20px);
    column-gap: 40px;
    font-size: 14px;
  }
}

@media screen and (max-width: 680px) {
  .form__list {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .form__list {
    gap: 18px;
    width: 100%;
  }
  .form__label {
    font-size: 15px;
    line-height: 31px;
    color: #121759;
  }
  .form__textArea {
    width: 100%;
  }
  .form__btn {
    margin-top: 50px;
    width: 260px;
    height: 57px;
    font-size: 18px;
  }
}
