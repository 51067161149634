.section{
    width: 100%;
    height: 100%;
}
.header{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    font-size: 16px;
}
.exercises{
    margin: 10px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 30%;
    font-size: 16px ;
    column-gap: 20px;
}
.list{
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
border-radius: 6px;
}