
.loader {
    min-height: 12px;
    margin: 10px auto;
    width: 50px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side,#2f42c2 90%,#0000) 0/calc(80%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1.4s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}