.section{
    width: 95%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header{
    width: 90%;
    margin-top: 42px;
    display: flex;
    justify-content: space-between;
}
.section__title {
    margin: 12px auto 24px;
    color: #2f42c2;
    font-size: 24px;
    font-weight: 600;
    width: fit-content;
}
.options{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding:0;
    margin: 0;
    width: 100%;
}
.option{
    display: grid;
    grid-template-columns: 50px 50px 1fr  1fr 50px 60px 40px;
    font-size: 16px;
    column-gap: 8px;
    align-items: center;
    margin-top: 4px;
}
.option_title{
    font-weight: 600;
    color: #121759;
}
.applyToAllQuestionsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
    font-size: 16px;
}
.group{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #5d3cc7;
    color:#fff;
    margin: 4px 0 0;
    padding: 6px 10px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    line-height: 1;
}
.submitBtn {
    margin: 43px auto;
    width: 212px;
    height: 46px;
    min-height: 46px;
    font-size: 16px;
    position: relative;
}
.submitBtn_disabled {
    opacity: 0.5;
    cursor: unset;
}
.submitBtn_disabled:hover {
    opacity: 0.5;
    cursor: unset;
    transform: unset;
}
.select{
    font-size: 17px;
}
@media screen and (max-width: 1300px) {
    .select{
        font-size: 14px;
    }

}