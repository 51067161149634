.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
}
.popup__text {
  margin: 25px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.1;
  max-width: 348px;
  text-align: center;
  color: #000000;
}
.popup__nextBtn {
  width: 260px;
  height: 57px;
}

@media screen and (max-width: 1400px) {
  .popup__text {
    font-size: 18px;
    max-width: 68%;
  }
  .popup__nextBtn {
    width: 203px;
    height: 42px;
    font-size: 16px;
  }

}
@media screen and (max-width: 600px) {
  .popup__text {
    font-size: 15px;
  }
  .popup__nextBtn {
    margin-top: 20px;
    width: 200px;
    font-size: 14px;
  }
}
