.section {
  width: 100%;
  box-sizing: border-box;
  padding: 30px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  line-height: 1.2;
}
.content {
  display: grid;
  grid-template-columns: 200px 1fr;
  width: 970px;
  max-width: 96%;
  margin: 0 auto;
  gap: 40px;
}
.images {
  display: grid;
  justify-content: center;
}
.card {
  width: 100%;
}
.card__title {
  margin: 0 auto 10px;
  color: #5d3cc7;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  box-sizing: border-box;
}
.card__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 14px;
  justify-content: center;
}
.card__li {
  width: 100%;
  display: grid;
  grid-template-columns: 40px 50px 35px repeat(4, 1fr) 38px 32px 32px 32px;
  justify-items: center;
  border-bottom: 1px solid #c9c9c9;
  /*border-bottom: none;*/
  box-sizing: border-box;
  gap: 4px;
}
.card__li_type_general{
  grid-template-columns: 35px 50px 1fr 35px repeat(4, 1fr)  32px 32px;

}
.card__li:last-child{
  border-bottom: 1px solid #c9c9c9;
}
.card__li_active{
  background-color: #7d91aa;
}
.card__li_title {
  font-size: 13px;
  font-weight: 600;
  border: none;
}
.card__text {
  white-space: nowrap;        /* Prevent text from wrapping to the next line */
  overflow: hidden;           /* Hide the overflowed text */
  text-overflow: ellipsis;    /* Display an ellipsis (...) at the end */
  width: 100%;
  transition: all 0.4s;
}
.card__text:hover {
  white-space: normal;        /* Allow text to wrap on hover */
  overflow: visible;          /* Show full content */
  /*width: auto;*/
  background-color: #d5d5d5;
  word-break: break-word;
}
.card__cell{
  width: 100%;
  height: 100%;
  display: flex;
  padding-right: 3px;
  /*justify-content: center;*/
  /*align-items: baseline;*/
}
.card__footer{
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
}
.texts {
}
.text {
  margin: 6px auto;
  font-size: 16px;
}
.list {
  list-style-type: none;
  font-size: 17px;
  padding-right: 25px;
}
.li {
  margin: 2px;
  display: flex;
  gap: 6px;
}
.li:before {
  content: "✓";
}
.submitBtn{
  margin: 20px auto;
}

@media screen and (max-width: 500px) {
  .content {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .texts {
    margin: 20px 5px;
  }
}
