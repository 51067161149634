.usersList {
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 46vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.usersListCell {
    display: flex;
    align-items: center;
    margin: 10px 4px 10px 0;
}
.user {
    display: grid;
    grid-template-columns: 60px 80px 120px 100px 94px 1fr;
    gap: 10px;
    color: #2c2b2b;
    font-size: 18px;
    width: 97%;
    margin: 0 auto;
    box-sizing: border-box;
}
.usersListHeader {
    color: #00214e;
    font-size: 20px;
    font-weight: 600;
}
.user_mobile {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 15px;
}
.user_colored {
    background-color: #cee7ff;
}
.btn {
    outline: none;
    border: none;
    height: 35px;
    width: 30px;
    font-family: inherit;
    background-image: url("../../images/actions_icon.svg");
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    position: relative;
    cursor: pointer;
}
/*.btn_type_actions:before {*/
/*    content: "";*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    position: absolute;*/
/*    right: 10px;*/
/*    top: 10px;*/
/*    background-image: url("../../images/settings_icon.svg");*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*}*/
/*.btn_type_actions:after {*/
/*    content: "";*/
/*    width: 12px;*/
/*    height: 12px;*/
/*    position: absolute;*/
/*    left: 10px;*/
/*    top: 10px;*/
/*    background-image: url("../../images/arrow_left_white.svg");*/
/*    background-repeat: no-repeat;*/
/*    background-size: contain;*/
/*    rotate: -90deg;*/
/*}*/
.recommendation{
    position: relative;
}
.recommendation:hover .recommendation_full {
    visibility: visible;
    opacity: 1;
}
.recommendation_full {
    visibility: hidden;
    width: 150px;
    background-color: #fff;
    color: #2c2b2b;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -30%;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
}
@media screen and (max-width: 1300px) {
    .usersHeader {
        padding: 14px 10px;
        font-size: 22px;
    }
    .usersList {
        max-height: 47vh;
    }
    .usersListHeader {
        font-size: 17px;
    }
    .usersListTitle {
        margin: 8px 0;
    }
    .user {
        font-size: 15px;
    }
    .userName {
        margin: 8px 10px;
        font-size: 16px;
        color: #000;
    }
    .userNameLabel {
        color: #626262;
        font-size: 16px;
        margin-right: 3px;
    }
}
@media screen and (max-width: 800px) {
    .usersListHeader {
        display: none;
    }
    .usersList {
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-height: 52vh;
    }
}
@media screen and (max-width: 600px) {
    .usersList {
        max-height: unset;
        overflow-y: unset;
    }
}