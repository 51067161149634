.users {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: space-between;
}
.loaderContainer {
  width: 100%;
  height: 100%;
  align-items: center;
}
.usersTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  margin: 20px;
  border-radius: 3px;
  position: relative;
}
.usersHeader {
  background-color: #a9d4ff;
  width: 100%;
  padding: 20px;
  margin: 0 0 10px;
  color: #2f42c2;
  box-sizing: border-box;
}
.usersSearchBar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2%;
  align-items: center;
}
.addButtonContainer {
  margin-left: 20px;
  align-self: flex-end;
  position: relative;
}
.usersInput {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  font: inherit;
  font-size: 18px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  line-height: 1;
}
.select{
  font-size: 18px;
}
.checkboxes {
  display: grid;
  grid-template-columns: 20px 1fr;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
}
.label {
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  width: 200px;
  line-height: 1;
}
.usersContent {
  width: 100%;
  margin-top: 54px;
}
.options {
  margin-top: 12%;
  justify-content: center;
}
.usersTotal {
  width: 97%;
  margin: 14px auto;
}
.noUsers {
  width: 97%;
  margin: 0 auto;
}
@media screen and (max-width: 1300px) {
  .addButtonContainer {
    margin-left: 14px;
  }
  .usersInput {
    padding: 8px 15px;
    font-size: 15px;
  }
  .select{
    font-size: 15px;
  }
  .usersTotal {
    font-size: 15px;
    margin: 8px auto;
  }
  .label {
    font-size: 16px;
    margin: 0;
    width: 200px;
    line-height: 1;
  }
}
@media screen and (max-width: 800px) {
  /*.usersInput {*/
  /*  margin: 14px 5px;*/
  /*}*/
  .usersSearchBar {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
  }
  .noUsers {
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  .usersContent{
    width: 95%;
    margin-top: 40px;
  }
  .usersTable {
    margin: 0 4px;
  }
  .usersSearchBar {
    grid-template-columns: 1fr;
    gap: 8%;
    margin: 12px 0;
  }

}

