.section{
  width: 97%;
  min-height: 100%;
  position: relative;
  padding-top: 24px;
  box-sizing: border-box;
}
.list{
  margin: 20px 0;
}
.listRow{
  grid-template-columns: 50px 1fr .5fr .5fr .5fr 120px;
}
