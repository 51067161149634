.rrmSection{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.section{
  width: 100%;
}