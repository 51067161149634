.popup{
    min-width: 320px;
}
.listRow{
    display: grid;
    grid-template-columns: 50px 110px 100px 100px 1fr  60px;
    width: 100%;
    grid-gap: 10px;
    color: #626262;
}
.listRow_header{
    font-weight: 600;
    color: #00214e;
}
.btn{
    cursor: pointer;
    margin: auto;
    height: 27px;
    background-color: transparent;
    border-radius: 4px;
    color: #da1e28;
    border: 1px solid #da1e28;
}
@media screen and (max-width: 1200px) {
    .listRow {
        grid-gap: 8px;
    }
}
/*@media screen and (max-width: 1000px) {*/
/*    .listRow {*/
/*        grid-template-columns: 50px 50px 50px 50px 1fr 130px 60px 130px;*/
/*    }*/
/*}*/